import { getMyMember } from '@wix/ambassador-members-v1-member/http';
import { Set as FieldSet } from '@wix/ambassador-members-v1-member/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export async function getMemberAccount(flowAPI: ControllerFlowAPI) {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const response = await withErrorHandler(() => httpClient.request(getMyMember({ fieldsets: [FieldSet.FULL] })), {
      errorCodesMap: {},
    });

    return response.data;
  } catch (error) {
    throw getResolvedError(error);
  }
}
