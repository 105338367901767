import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { SimpleRule, SocialMediaChannel } from '../../types/domain';
import { createBirthdayRuleDescription, createRuleDescription } from '../create-rule-description';
import { isBirthdayEarningRule, isSocialMediaEarningRule } from '../earningRules';
import { isValidChannelType } from '../social-media';

interface ToSimpleRulesParams {
  rules: LoyaltyEarningRule[];
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
}

export const toSimpleRules = ({ rules, flowAPI, loyaltyProgram }: ToSimpleRulesParams): SimpleRule[] => {
  const rulesArray = rules.reduce((prev, rule) => {
    const results: SimpleRule[] = [];

    if (rule.status !== Status.ACTIVE) {
      return prev;
    }
    if ('conversionRate' in rule) {
      rule.conversionRate!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription({ rule, flowAPI, loyaltyProgram, configIndex: index }),
          tierId: config.tierId,
        };
        results.push(result);
      });
    } else if ('fixedAmount' in rule) {
      rule.fixedAmount!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription({ rule, flowAPI, loyaltyProgram, configIndex: index }),
          tierId: config.tierId,
          birthday: isBirthdayEarningRule(rule)
            ? { description: createBirthdayRuleDescription({ loyaltyProgram, flowAPI, points: config.points ?? 0 }) }
            : undefined,
        };

        if (isSocialMediaEarningRule(rule)) {
          const channelsSortOrder = Object.values(SocialMediaChannel);
          const channels = loyaltyProgram.socialMediaSettings?.channels
            ?.filter((channel) => channel.enabled && isValidChannelType(channel.type))
            .map((channel) => ({
              type: channel.type as unknown as SocialMediaChannel,
              url: channel.url!,
            }))
            .sort((a, b) => channelsSortOrder.indexOf(a.type) - channelsSortOrder.indexOf(b.type));

          result.socialMedia = channels ? { channels } : undefined;
        }
        results.push(result);
      });
    }

    return [...prev, ...results];
  }, [] as SimpleRule[]);
  return rulesArray;
};
