import { LoyaltyEarningRule } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward } from '@wix/ambassador-loyalty-v1-reward/types';
import { Tier, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { SocialMediaChannel } from '../types/domain';
import { getLoyaltyEarningRules } from './getLoyaltyEarningRules';
import { getLoyaltyProgram } from './getLoyaltyProgram';
import { getLoyaltyRewards } from './getLoyaltyRewards';
import { getLoyaltyTiersProgram } from './getLoyaltyTiersProgram';
import { loadUserData } from './loadUserData';

export interface LoadDataResult {
  earningRules: LoyaltyEarningRule[];
  rewards: Reward[];
  tiersProgramSettings: TiersProgramSettings;
  tiers: Tier[];
  loyaltyProgram: LoyaltyProgram;
  currentTierId?: string;
  followedChannels: SocialMediaChannel[];
}

const WARMUP_DATA_KEY = 'loadData';

export async function loadData(
  flowAPI: ControllerFlowAPI,
  preloadedLoyaltyProgram?: LoyaltyProgram,
): Promise<LoadDataResult> {
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { isSSR, isViewer } = flowAPI.environment;
  const useWarmupData = isViewer;
  const { warmupData } = wixCodeApi.window;
  const isLoggedIn = wixCodeApi.user.currentUser.loggedIn;

  // Reuse backend API responses from SSR on the client-side
  if (useWarmupData && !isSSR) {
    const dataFromSSR = warmupData.get(WARMUP_DATA_KEY);
    if (dataFromSSR) {
      return dataFromSSR;
    }
  }

  const loyaltyProgramPromise = !preloadedLoyaltyProgram ? getLoyaltyProgram(flowAPI) : undefined;
  const rewardsPromise = getLoyaltyRewards(flowAPI);
  const earningRulesPromise = getLoyaltyEarningRules(flowAPI);
  const tiersProgramPromise = getLoyaltyTiersProgram(flowAPI);

  const [rewards, earningRules, tiersProgramResponse, loyaltyProgramResponse] = await Promise.all([
    rewardsPromise,
    earningRulesPromise,
    tiersProgramPromise,
    loyaltyProgramPromise,
  ]);

  const tiersProgramSettings = tiersProgramResponse.programSettings ?? {};
  const tiers = tiersProgramResponse.tiers ?? [];
  const loyaltyProgram = preloadedLoyaltyProgram || loyaltyProgramResponse!;
  const { currentTierId, followedChannels } = isLoggedIn
    ? await loadUserData(flowAPI, earningRules, tiersProgramSettings)
    : { followedChannels: [] };

  const data = {
    earningRules,
    rewards,
    tiersProgramSettings,
    tiers,
    loyaltyProgram,
    currentTierId,
    followedChannels,
  };

  // Forward backend API responses from SSR to client-side
  if (useWarmupData && isSSR) {
    warmupData.set(WARMUP_DATA_KEY, data);
  }

  return data;
}
