import { listFollowedChannels } from '@wix/ambassador-loyalty-socialmedia-v1-followed-channel/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { SocialMediaChannel } from '../../types/domain';
import { isValidChannelType } from './isValidChannelType';

export async function getFollowedChannels(flowAPI: ControllerFlowAPI): Promise<SocialMediaChannel[]> {
  const { httpClient } = flowAPI;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;

  try {
    const { data } = await withErrorHandler(() => httpClient.request(listFollowedChannels({})), {
      errorCodesMap: {},
    });

    return data.followedChannels?.map(({ channel }) => channel).filter((channel) => isValidChannelType(channel)) ?? [];
  } catch (error) {
    // Loyalty account doesn't exist
    if (error instanceof Error && error.message.includes('404')) {
      return [];
    }

    throw getResolvedError(error);
  }
}
