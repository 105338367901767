import { updateMember } from '@wix/ambassador-members-v1-member/http';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const updateMemberBirthday = async (birthday: string, flowAPI: ControllerFlowAPI) => {
  const { httpClient } = flowAPI;
  const { wixCodeApi } = flowAPI.controllerConfig;
  const { withErrorHandler, getResolvedError } = flowAPI.errorHandler;
  const memberId = wixCodeApi.user.currentUser.id;

  try {
    await withErrorHandler(
      () =>
        httpClient.request(
          updateMember({
            member: {
              id: memberId,
              contact: {
                birthdate: birthday,
              },
            },
          }),
        ),
      {
        errorCodesMap: {
          validationError: {},
          applicationError: {
            INVALID_CUSTOM_FIELD_URL: false,
          },
        },
      },
    );
  } catch (error) {
    throw getResolvedError(error);
  }
};
